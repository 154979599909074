import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { RoutesEnum } from './enums';
import { observer } from 'mobx-react-lite';
import { lazy, useEffect, useState } from 'react';
import { useStores } from '../hooks';
import { getCategories, getUser } from './api';
import Loader from '../components/Loader/Loader';
import Search from '../pages/Search/Search';
import {
  iOS,
  isAppleDevice,
  isChromeBrowser,
  isInstagramInAppBrowser,
  isSafariBrowser
} from './helpers';
import { PaymentMethodViewModel } from './backend/models';
import {
  getAllUserData,
  getUserProfileData
} from '../components/Modal/AuthModal/AuthModal.helper';
import {
  APPLE_PAY_METHOD,
  CREDIT_DEBIT_METHOD,
  GOOGLE_PAY_METHOD
} from './consts';
import appToast from './toast';
import ContextsLayout from '../layouts/ContextsLayout/ContextsLayout';

interface IAppRoutes {
  path: string;
  element: JSX.Element;
}

/* HOME PAGE */
const Home = lazy(() => import('../pages/Home/Home'));

/* CATEGORY PAGE */
const Category = lazy(() => import('../pages/Category/Category'));

/* EVENTS BY BANNER ID PAGE */
const TagEventsList = lazy(() => import('../pages/TagEventList/TagEventList'));

/* CLIPS PAGE */
const Clips = lazy(() => import('../pages/Moments/Moments'));

/* SHARED CLIP */
const SharedClip = lazy(() => import('../pages/Videos/Videos'));

/* EVENTS PAGE */
const Events = lazy(() => import('../pages/Gigs/Gigs'));

/* EVENT PAGE */
const Experience = lazy(() => import('../pages/Experience/Experience'));

/* BOOK EVENT PAGE */
const Book = lazy(() => import('../pages/Book/Book'));

/* BOOKINGS PAGE */
const Bookings = lazy(() => import('../pages/Bookings/Bookings'));

/* USER PROFILE PAGE */
const UserProfile = lazy(() => import('../pages/VendorProfile/VendorProfile'));

// const eventsManagement = lazy(() => import('../pages/Events/Events')); <-- This route has been depreacted. Consider removing page later

/* CREATE AND EDIT EVENT PAGE */
const CreateEvent = lazy(() => import('../pages/CreateEvent/CreateEvent'));

/* EVENT DETAILS PAGE */
const EventManagement = lazy(
  () => import('../pages/EventManagement/EventManagement')
);

/* EVENT CHECK-IN PAGE */
const EventCheckIn = lazy(() => import('../pages/CheckIn/CheckIn'));

/* EVENTS CHECK-IN PAGE FOR HOOPLA ADMINS */
const AltEventCheckIn = lazy(
  () => import('../pages/EventsManager/EventsManager')
);

/* REGISTRATION PANEL PAGES */
const RegistrationAttendees = lazy(
  () => import('../pages/RegistrationAttendees/RegistrationAttendees')
);
const RegistrationGuestList = lazy(
  () => import('../pages/RegistrationGuestList/RegistrationGuestList')
);
const RegistrationRegisterList = lazy(
  () => import('../pages/RegistrationRegisterList/RegistrationRegisterList')
);
const RegistrationInviteeList = lazy(
  () => import('../pages/RegistrationInviteeList/RegistrationInviteeList')
);
const InvitationCodeRedirectPage = lazy(
  () => import('../pages/InvitationCode/InvitationCode')
);

/* RESET PASSWORD PAGE */
const ResetPassword = lazy(
  () => import('../pages/ResetPassword/ResetPassword')
);

/* PRIVACY PAGE */
const Privacy = lazy(() => import('../pages/Privacy/Privacy'));

/* TERMS PAGE */
const Terms = lazy(
  () => import('../pages/TermsAndConditions/TermsAndConditions')
);

/* LEGAL TERMS PAGE */
const LegalTerms = lazy(() => import('../pages/LegalTerms/LegalTerms'));

/* ABOUT US PAGE */
const AboutUs = lazy(() => import('../pages/AboutUs/AboutUs'));

/* EXTERNAL INTEGRATIONS PAGE */
const ExternalIntegrations = lazy(
  () => import('../pages/ExternalIntegrations/ExternalIntegrations')
);

/* ERROR PAGE */
const ErrorPage = lazy(() => import('../pages/ErrorPage/ErrorPage'));

/* REDIRECT TO EXPERIENCE PAGE */
const RedirectToExperience = lazy(
  () => import('../pages/RedirectToExperience/RedirectToExperience')
);

export const appRoutes: IAppRoutes[] = [
  {
    path: RoutesEnum.Root,
    element: <Home />
  },
  {
    path: RoutesEnum.Invitation,
    element: <InvitationCodeRedirectPage />
  },
  {
    path: RoutesEnum.Notifications,
    element: <Home />
  },
  {
    path: RoutesEnum.RegistrationAttendees,

    element: <RegistrationAttendees />
  },
  {
    path: RoutesEnum.RegistrationGuests,

    element: <RegistrationGuestList />
  },
  {
    path: RoutesEnum.RegistrationRegisterList,

    element: <RegistrationRegisterList />
  },
  {
    path: RoutesEnum.RegistrationInviteeList,

    element: <RegistrationInviteeList />
  },
  {
    path: RoutesEnum.ShortUrlRedirect,
    element: <Home />
  },
  {
    path: RoutesEnum.EventsManager,
    element: <AltEventCheckIn />
  },
  {
    path: RoutesEnum.Video,
    element: <Home />
  },
  {
    path: RoutesEnum.VideoComment,
    element: <Home />
  },
  {
    path: RoutesEnum.Experience,
    element: <Experience />
  },
  {
    path: RoutesEnum.Messages,
    element: <Home />
  },
  {
    path: RoutesEnum.Book,
    element: <Book />
  },
  {
    path: RoutesEnum.CreateEvent,
    element: <CreateEvent />
  },
  {
    path: RoutesEnum.EditEvent,
    element: <CreateEvent />
  },
  {
    path: RoutesEnum.EventCheckIn,
    element: <EventCheckIn />
  },
  {
    path: RoutesEnum.EventCheckInWithId,
    element: <EventCheckIn />
  },
  {
    path: RoutesEnum.EventManagementSection,
    element: <EventManagement />
  },
  {
    path: RoutesEnum.EventManagement,
    element: <EventManagement />
  },
  {
    path: RoutesEnum.Tickets,
    element: <Home />
  },
  {
    path: RoutesEnum.ResetPassword,

    element: <ResetPassword />
  },
  {
    path: '/tamaraanthony',
    element: <Navigate to='/u/tamaraanthony' />
  },
  {
    path: '/gcm',
    element: <Navigate to='/u/grandcentralmarket' />
  },
  {
    path: RoutesEnum.VendorProfile,
    element: <UserProfile />
  },
  {
    path: '/creators/:id', // <-- Old url (consider removing it)
    element: <UserProfile />
  },
  {
    path: RoutesEnum.VendorBookings,
    element: <UserProfile />
  },
  {
    path: RoutesEnum.TagEventList,
    element: <TagEventsList />
  },
  {
    path: RoutesEnum.Categories,
    element: <Category />
  },
  {
    path: RoutesEnum.Clips,

    element: <Clips />
  },
  {
    path: RoutesEnum.ClipsForCategory,

    element: <Clips />
  },
  {
    path: RoutesEnum.Videos,
    element: <SharedClip />
  },
  {
    path: RoutesEnum.Experiences,
    element: <Events />
  },
  {
    path: RoutesEnum.Events,
    element: <Events />
  },
  {
    path: RoutesEnum.NearBy,
    element: <Events />
  },
  {
    path: RoutesEnum.Search,
    element: <Search />
  },
  {
    path: RoutesEnum.Privacy,
    element: <Privacy />
  },
  {
    path: RoutesEnum.Terms,
    element: <Terms />
  },
  {
    path: RoutesEnum.LegalTerms,
    element: <LegalTerms />
  },
  {
    path: RoutesEnum.About,
    element: <AboutUs />
  },
  {
    path: RoutesEnum.ExternalIntegrations,
    element: <ExternalIntegrations />
  },
  {
    path: RoutesEnum.Bookings,
    element: <Bookings />
  },
  {
    path: RoutesEnum.Booking,
    element: <Bookings />
  },
  {
    path: RoutesEnum.Error,
    element: <ErrorPage />
  },
  {
    path: '/:genericId',
    element: <RedirectToExperience />
  },
  {
    path: '*',
    element: <RedirectToExperience />
  }
];

const AppRouter = () => {
  const {
    userStore,
    bookingsStore,
    likedMedia,
    creatorStore,
    followUsers,
    categoryStore,
    locationStore,
    appDataManager,
    stripeStore
  } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.user) return;

    appDataManager.loadData();

    if (appDataManager.data) {
      locationStore.saveLocation(
        appDataManager.data.hyperLocal?.fullAddress,
        appDataManager.data.hyperLocal?.coords
      );
    }

    getAppRequiredData();
  }, [userStore.user]);

  const getAuthenticatedUserData = async (shouldGetBookings: boolean) => {
    try {
      setIsLoading(true);

      const {
        userBookings,
        creatorBookings,
        userLikes,
        following,
        paymentMethods
      } = await getAllUserData(shouldGetBookings);

      bookingsStore.saveToStore(userBookings, creatorBookings);
      likedMedia.saveToStore(userLikes);
      followUsers.saveToStore(following);
      userStore.savePaymentMethods(paymentMethods);

      setIsLoading(false);
    } catch (e: any) {
      if (e.response?.data?.errorCode === 'HE-23003') {
        appToast.showError(
          'Your IP address has been blocked due to suspicious activity.'
        );
        return navigate(RoutesEnum.Error404);
      }

      if (userStore.user && e.response.status === 401) {
        userStore.saveUserToStore(null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAppRequiredData = async () => {
    try {
      setIsLoading(true);
      // Get Categories
      const categories = await getCategories();
      categoryStore.saveToStore(categories);

      // Save default payment methods
      const _paymentMethods: PaymentMethodViewModel[] = [];

      if (isAppleDevice() && (isSafariBrowser() || isInstagramInAppBrowser())) {
        _paymentMethods.push(APPLE_PAY_METHOD);
      }

      if (!iOS() && (isChromeBrowser() || isInstagramInAppBrowser())) {
        _paymentMethods.push(GOOGLE_PAY_METHOD);
      }

      if (!userStore.user) _paymentMethods.push(CREDIT_DEBIT_METHOD);
      userStore.savePaymentMethods(_paymentMethods);

      // Check if user is authenticated
      const user = await getUser();

      // Store user data
      userStore.saveUserToStore(user);

      // Get user profile data
      const userIsCreatorPro = user.isCreatorPro || user.isProviderPro;
      const { events, clips, summary, followers } = await getUserProfileData(
        user.id,
        userIsCreatorPro
      );
      userStore.saveMediaToStore(events, clips);
      userStore.saveStatistics(summary);
      userStore.saveFollowers(followers);

      // Get user data
      getAuthenticatedUserData(userIsCreatorPro);
    } catch (e: any) {
      if (e.response?.data?.errorCode === 'HE-23003') {
        appToast.showError(
          'Your IP address has been blocked due to suspicious activity.'
        );
        return navigate(RoutesEnum.Error404);
      }
      creatorStore.saveToStore([]);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Routes>
      <Route element={<ContextsLayout />}>
        {appRoutes.map((route, index) => {
          return <Route key={index} {...route} />;
        })}
      </Route>
    </Routes>
  );
};

export default observer(AppRouter);
